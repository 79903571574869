import styled from "styled-components";
import { Link } from 'react-scroll';

export const FlexDiv = styled.div`
    display: flex;
`;
export const AnchorTag = styled(Link)`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
`;
