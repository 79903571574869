import React from 'react'
import { MainDiv} from './Style'
import { useMediaQuery } from 'react-responsive'
import { FlexDiv } from '../../assets/Style';
import Ent from '../../assets/ENT.png';
import Ibt from '../../assets/IBT.png';

const Companies = () => {
  const isTab = useMediaQuery({ maxWidth: 950 });
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const isSMobile = useMediaQuery({ maxWidth: 420 });

  return (
    <MainDiv 
      style={{
        gap:isTab?'2rem':'',
        alignItems:'center'
      }}
    >
      <FlexDiv 
        style={{
          gap:isTab?'2rem':''
        }}
      >
        <img alt='' src={Ent} style={{width:isMobile?(isSMobile?'9rem':'10rem'):'20rem', filter:'opacity(0.5)'}}/>
        <img alt='' src={Ibt} style={{width:isMobile?(isSMobile?'9rem':'10rem'):'20rem', filter:'opacity(0.5)'}}/>
      </FlexDiv>
    </MainDiv>
  )
}

export default Companies
