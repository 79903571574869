import React from 'react'
import { MainDiv, Card, InnerCard, CardDetails, LearnMore, BlueArrowImg, SoldDiv } from './Style'
import { FlexDiv } from '../../assets/Style';
import { CardData } from './Data';
import { useMediaQuery } from 'react-responsive';

const ProjectsList = () => {
    const isLaptop = useMediaQuery({ maxWidth: 1300 });
    const isSmallLaptop = useMediaQuery({ maxWidth: 1200 });
    // const isMobile = useMediaQuery({maxWidth:550});    
    const isSmallMobile = useMediaQuery({ maxWidth: 450 });

    return (
        <MainDiv id='Projects' style={{ padding: isSmallMobile ? '2.5rem 0rem' : '' }}>
            {/* <FlexDiv style={{marginTop: '0.8rem', justifyContent: 'center', gap:isSmallLaptop?'':'7.25rem', flexDirection:isSmallLaptop?'column':'', alignItems:isSmallLaptop?(isSmallMobile?'':'center'):'', marginLeft:isMobile?'1rem':'', marginRight:isMobile?'1rem':''}}>
            <FlexDiv style={{width:isSmallLaptop?'':'35.3125rem'}}>
                <Heading style={{textAlign:isSmallLaptop?(isSmallMobile?'':'center'):'', fontSize:isMobile?'1.75rem':''}}>
                    Amazing Projects! <br /> Let’s check it out.
                </Heading>
                <BlueLine style={{marginTop:'-0.75rem', marginLeft:'-0.5rem'}}/>
            </FlexDiv>
            <Details style={{marginTop: isLaptop?'0.2rem':'1.5rem',lineHeight:isLaptop?'1.5rem':'', textAlign:isSmallLaptop?(isSmallMobile?'':'center'):'', fontSize:isMobile?'1rem':''}}>
                Meet Luly, a generative Al voice bot that is capable of <br /> handing full conversations over phone calls and can <br /> autonomously execute tasks.
            </Details>
        </FlexDiv> */}
            <FlexDiv style={{ justifyContent: 'center', gap: '1.5rem', flexDirection: isSmallLaptop ? 'column' : '', alignItems: isSmallLaptop ? 'center' : '' }}>
                {CardData.map((data, index) => (
                    <Card style={{ padding: isLaptop ? '2.38rem 1.5rem 2rem 1.5rem' : '2.69rem 2rem 2.25rem 2rem' }}>
                        <InnerCard style={{ padding: `${data.padding}` }}>
                            {isLaptop ? data.smallImage : data.image}
                            {data.sold &&
                                <SoldDiv>Sold</SoldDiv>
                            }
                        </InnerCard>
                        <CardDetails style={{ width: isLaptop ? '19.375rem' : '20rem', marginTop: '2.73rem' }}>
                            {data.details}
                        </CardDetails>
                        {data.urlLink &&
                            <button style={{ display: 'contents', cursor: 'pointer' }}
                                onClick={() => { window.open(data.urlLink, '_blank'); }}
                            >
                                <FlexDiv style={{ marginTop: '2rem' }}>
                                    <LearnMore>
                                        Learn More
                                    </LearnMore>
                                    <BlueArrowImg style={{ marginLeft: '0.75rem', marginTop: '0.25rem' }} />
                                </FlexDiv>
                            </button>
                        }
                    </Card>
                ))}
            </FlexDiv>
        </MainDiv>
    )
}

export default ProjectsList