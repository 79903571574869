import './App.css';
import Router from './Router/Router';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {

  return (
    <BrowserRouter>
      <Router />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
