import styled from 'styled-components'
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';

export const NavabrDiv = styled.div`
    border-radius: 16px;
    background: #000;
    box-shadow: 0px 12px 18px 0px rgba(56, 68, 85, 0.10);    
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    position: fixed;
    z-index: 100;
    transition: background 0.3s ease;    
`;

export const NavabrInnerText = styled.div`
    display: flex;
    gap: 1.5rem;
`;

export const NavabrText = styled.h2`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    transition: background 0.3s ease;    
    &:hover {
        color: #0090FF;
    }    
`;

export const NavabrBtn = styled(motion.div)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: linear-gradient(90deg, #0090FF 0%, #36DAE9 100%);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    width: max-content;
    padding: 0rem 1.5rem;
    height: 3.625rem;
`;

export const SubscribeText = styled.h2`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
`;

export const FrankLogo = styled.img`
    width: 255px;
    height: 48.05px;
`;

export const AnchorTag = styled(Link)`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
`;

export const NavImage = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;

export const ContentBtn = styled.button`
    display:contents;
    cursor:pointer;
`;

export const NavText = styled.div`
    color: #06152B;
    text-align: center;
    font-family: Merriweather Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    &:hover {
        color: #0090FF;
    }        
`;
export const FlexDiv = styled.div`
    display: flex;
`;
export const MobileNavDiv = styled(motion.div)`
    width: 24.375rem;
    height:100%;
    z-index: 100;
    background: white;
    position:fixed;
    right: 0;
`;
export const MobileNavMenuText = styled.div`
    color: #06152B;
    text-align: center;
    font-family: Merriweather Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 100% */
    display: flex;
    align-items: center;
`;
export const MobileNavX = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
