import React, { useState, useEffect } from 'react'
import NavbarLogo from "../../assets/NavbarLogo.svg";
import NavbarLogoTWO from "../../assets/NavbarLogo.png";
import SubscribeBtnImg from '../../assets/subscribe_btn_img.svg';
import { NavabrDiv, FrankLogo, NavabrInnerText, AnchorTag, NavabrBtn, NavabrText, SubscribeText, NavImage, ContentBtn } from './Style';
import { useMediaQuery } from 'react-responsive';
import NavImg from '../../assets/NavImg.svg'
import NavImgTwo from '../../assets/NavImgTwo.svg'
import { AnimatePresence } from 'framer-motion';
import MobileNav from './MobileNav';
import { useNavigate } from 'react-router-dom';
import NewsLetterPopup from '../Hero/NewsLetterPopup';

const Navabr = ({ navBgColor = '#000', isHome = true }) => {
    const isLaptop = useMediaQuery({ maxWidth: 1300 });
    const isTab = useMediaQuery({ maxWidth: 900 });
    const [scrolling, setScrolling] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <NewsLetterPopup isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <NavabrDiv style={{ width: isLaptop ? '88%' : '74rem', height: isTab ? '50px' : '88px', padding: isTab ? '0rem 0.75rem' : '0rem 1.5rem 0rem 1.5rem', background: scrolling ? '#fff' : navBgColor }}>
                    <FrankLogo
                        alt=''
                        src={scrolling ? NavbarLogoTWO : NavbarLogo}
                        style={{
                            width: isTab ? '135px' : '255px',
                            height: isTab ? '26px' : '48.05px'
                        }}
                    />
                    {false ?
                        <button
                            style={{ cursor: 'pointer', display: 'contents' }}
                            onClick={() => { setMobileNav(true) }}
                        >
                            <NavImage alt='' src={scrolling ? NavImg : NavImgTwo} />
                        </button>
                        :
                        <>
                            {/* {isHome ?
                                <NavabrInnerText>
                                    <NavabrText style={{ color: scrolling ? '#06152B' : '' }}>
                                        <AnchorTag
                                            to='Home'
                                            smooth={true}
                                        >
                                            Home
                                        </AnchorTag>
                                    </NavabrText>
                                    <ContentBtn onClick={() => { navigate(`/about`) }}>
                                        <NavabrText style={{ color: scrolling ? '#06152B' : '' }}>
                                            About
                                        </NavabrText>
                                    </ContentBtn>
                                    <NavabrText style={{ color: scrolling ? '#06152B' : '' }}>
                                        <AnchorTag
                                            to='Projects'
                                            smooth={true}
                                        >
                                            Projects
                                        </AnchorTag>
                                    </NavabrText>
                                </NavabrInnerText>
                                :
                                <NavabrInnerText>
                                    <ContentBtn onClick={() => { navigate(`/`) }}>
                                        <NavabrText style={{ color: scrolling ? '#06152B' : '' }}>
                                            Home
                                        </NavabrText>
                                    </ContentBtn>
                                    <ContentBtn onClick={() => { navigate(`/about`) }}>
                                        <NavabrText style={{ color: scrolling ? '#06152B' : '' }}>
                                            About
                                        </NavabrText>
                                    </ContentBtn>
                                    <ContentBtn onClick={() => { navigate(`/?section=Projects`) }}>
                                        <NavabrText style={{ color: scrolling ? '#06152B' : '' }}>
                                            Projects
                                        </NavabrText>
                                    </ContentBtn>
                                </NavabrInnerText>
                            } */}

                            <NavabrBtn
                                whileHover={{ opacity: 0.8, scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: 'easeInOut' }}
                                // style={{ marginLeft: '89px', cursor: 'pointer' }}
                                style={{ cursor: 'pointer', height:isTab?'2.5rem':'3.625rem', padding:isTab?'0rem 0.75rem':'0rem 1.5rem'}}
                                onClick={() => setIsModalOpen(true)}
                            >
                                {!isTab && <img alt='' src={SubscribeBtnImg} />}
                                <SubscribeText style={{fontSize:isTab?'14px':'18px'}}>
                                    Join My Newsletter
                                </SubscribeText>
                            </NavabrBtn>
                        </>
                    }
                </NavabrDiv>
            </div>
            <AnimatePresence>
                {isTab && mobileNav && <MobileNav setMobileNav={setMobileNav} isHome={isHome} setIsOpen={setIsModalOpen} />}
            </AnimatePresence>
        </>
    )
}
export default Navabr
