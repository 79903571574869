import styled from 'styled-components'
import {motion} from 'framer-motion';

export const Heading = styled.div`
    color: #FFF;
    font-family: Poppins;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.625rem;
    max-width: 23.75rem;
    z-index: 10;
    display:flex;
`;

export const Detail = styled.div`
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125rem;
    width: 32.6875rem;
    opacity: 0.9;
`;

export const FrankImg = styled.img`
    width: 37rem;
    height: 39rem;
    z-index:2;
`;

export const BlurredBackground = styled.div`
  position: absolute;
  width: 34rem;
  height: 40rem;
  border-radius: 28.1875rem;
  background: linear-gradient(180deg, rgba(14, 28, 59, 0.6) 0%, rgba(2, 10, 32, 0.4) 100%);
  filter: blur(24px);
  z-index: 1;
`;

export const SubscribeBtn = styled(motion.div)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: linear-gradient(90deg, #0090FF 0%, #36DAE9 100%);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    width: max-content;
    padding: 0rem 1.5rem;
    height: 3.625rem;
`;

export const SubscribeText = styled.h2`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    @media (max-width: 600px) {
        line-height: 18px;
    }
`;

export const ProjectsBtn = styled(motion.div)`
    display: inline-flex;
    padding: 1.0625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1rem;
    border: 1px solid #0090FF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);    
`;

export const BlurImageDiv = styled.div`
    width: 34.125rem;
    height: 7.25rem;
    background: linear-gradient(180deg, #000 0%, rgba(6, 21, 43, 0.00) 100%);
`;