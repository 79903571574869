import { InstaImg, FacebookImg, LinkedInImg } from "./Style";

export const SocialLinks = [
    {
        name: <FacebookImg />,
        url: 'https://www.facebook.com/frankmillerrei'
    },
    {
        name: <LinkedInImg />,
        url: 'https://www.linkedin.com/in/franksystems/'
    },
    {
        name: <InstaImg />,
        url: 'https://www.instagram.com/franksystems/'
    }
];

export const API_URL = 'https://portal.luly.ai/api/';

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;