import { LulyImg, ParetoImage, SimplifyImage, SmallLulyImg, SmallParetoImage, SmallSimplifyImage } from "./Style"

export const CardData = [
    {
        image: <ParetoImage />,
        smallImage: <SmallParetoImage />,
        sold: false,
        name: 'Pareto Process',
        details: 'Consulting agency that develops enterprise sales solutions and software products.',
        padding: '1.35rem 1rem',
        urlLink: 'https://paretoprocess.com/',
    },
    {
        image: <LulyImg />,
        smallImage: <SmallLulyImg />,
        sold: false,
        name: 'Luly',
        details: 'Luly is a conversational voice AI that can execute tasks, sound, and feel human.',
        padding: '1rem',
        urlLink: 'https://www.luly.ai/',
    },    
    {
        image: <SimplifyImage />,
        smallImage: <SmallSimplifyImage />,
        sold: true,
        name: 'SimplifyREI',
        details: 'Lead conversion system that helps real estate investors convert more leads into deals.',
        padding: '0.9rem 1rem',
    }
]
