import React from 'react'
import { NavText, ContentBtn, AnchorTag, FlexDiv, MobileNavDiv, MobileNavMenuText, MobileNavX, NavabrBtn, SubscribeText } from './Style';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MobileNavCrossImg from '../../assets/MobileNavCross.svg';
import SubscribeBtnImg from '../../assets/subscribe_btn_img.svg';

const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '100%' }, // Slide out to the left
};

const MobileNav = ({ setMobileNav, isHome = true, setIsOpen }) => {
    const navigate = useNavigate();
    // const isLaptop = useMediaQuery({maxWidth:600});
    const isSmallMobile = useMediaQuery({ maxWidth: 400 });
    const handleButtonClick = () => {
        setIsOpen(true);
        setMobileNav(false);
    }
    return (
        <>
            <MobileNavDiv
                variants={variants}
                initial="closed"
                animate="open"
                exit="closed"
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                style={{ width: isSmallMobile ? '100%' : '' }}
            >
                <FlexDiv
                    style={{
                        padding: '2.5rem 1.5rem',
                        justifyContent: 'space-between'
                    }}
                >
                    <MobileNavMenuText>
                        Menu
                    </MobileNavMenuText>
                    <ContentBtn
                        onClick={() => { setMobileNav(false) }}
                    >
                        <MobileNavX alt='' src={MobileNavCrossImg} />
                    </ContentBtn>
                </FlexDiv>
                <FlexDiv
                    style={{
                        marginTop: '3rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '2.25rem',
                        flexDirection: 'column'
                    }}
                >
                    {
                        isHome ?
                            <>
                                <NavText
                                >
                                    <AnchorTag
                                        to='Home'
                                        smooth={true}
                                        onClick={() => { setMobileNav(false) }}
                                    >
                                        Home
                                    </AnchorTag>
                                </NavText>
                                <ContentBtn onClick={() => { navigate(`/about`) }}>
                                    <NavText
                                    >
                                        About
                                    </NavText>
                                </ContentBtn>
                                <NavText
                                >
                                    <AnchorTag
                                        to='Projects'
                                        smooth={true}
                                        onClick={() => { setMobileNav(false) }}
                                    >
                                        Projects
                                    </AnchorTag>
                                </NavText>
                            </>
                            :
                            <>
                                <ContentBtn onClick={() => { navigate(`/`) }}>
                                    <NavText
                                    >
                                        Home
                                    </NavText>
                                </ContentBtn>
                                <ContentBtn onClick={() => { navigate(`/about`) }}>
                                    <NavText
                                    >
                                        About
                                    </NavText>
                                </ContentBtn>
                                <ContentBtn onClick={() => { navigate(`/?section=Projects`) }}>
                                    <NavText
                                    >
                                        Projects
                                    </NavText>
                                </ContentBtn>
                            </>
                    }
                </FlexDiv>
                <FlexDiv
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1rem',
                        position: 'absolute',
                        bottom: '0',
                        marginBottom: '3rem',
                        width: '100%'
                    }}
                >

                    <NavabrBtn
                        whileHover={{ opacity: 0.8, scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                        onClick={handleButtonClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <img alt='' src={SubscribeBtnImg} />
                        <SubscribeText>
                            Join My Newsletter
                        </SubscribeText>
                    </NavabrBtn>
                </FlexDiv>
            </MobileNavDiv>
        </>
    )
}

export default MobileNav