import React from 'react';
import Modal from 'react-modal';
import SuccessIcon from '../../assets/success-icon.svg';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '../../assets/cancel.svg'
import { FlexDiv } from '../../assets/Style';

const modalHeaderStyles = {
    position: 'relative',
    width: '37.5rem',
    height: '6.125rem',
    borderRadius: '0.9375rem 0.9375rem 0rem 0rem',
    background: '#06152B',
};

const iconStyles = {
    position: 'absolute',
    width: '9.375rem',
    height: '9.375rem',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1001,
};

const messageStyles = {
    width: '32.6875rem',
    color: '#06152B',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    marginTop: '7.44rem'
};

const additionalInfoStyles = {
    width: '32.6875rem',
    color: 'rgba(6, 21, 43, 0.80)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.625rem',
    letterSpacing: '0.03125rem',
    marginTop: '0.5rem',
    opacity: '0.6'
};

const buttonStyles = {
    display: 'inline-flex',
    padding: '1.0625rem 1.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.375rem',
    borderRadius: '1rem',
    background: 'linear-gradient(90deg, #0090FF 0%, #36DAE9 100%)',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.06)',
    marginTop: '2.75rem',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontSize: '1rem'

};
const modalOverlayStyles = {
    border: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    padding: 0
};

Modal.setAppElement('#root');

const SuccessPopup = ({ isOpen, onClose }) => {
    const isMobile = useMediaQuery({ maxWidth: 650 });

    const modalContentStyles = {
        border: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '95%' : '37.5rem',
        height: 'max-content',
        borderRadius: '0.9375rem',
        background: 'rgb(6, 21, 43)',
        overflow: 'auto',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={{ overlay: modalOverlayStyles, content: modalContentStyles }}
        >
            <div style={{ ...modalHeaderStyles, width: isMobile ? '100%' : '37.5rem' }}>
                <img src={SuccessIcon} alt="Success Icon" style={iconStyles} />
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'center', background:'#fff'}}>
                <div>
            <div style={{ ...messageStyles, width: isMobile ? '100%' : '32.6875rem', fontSize:isMobile?'1.25rem':'2rem', marginTop:isMobile?'5rem':'7.44rem'}}>Email Sent Successfully</div>
            <div style={{ ...additionalInfoStyles, width: isMobile ? '100%' : '32.6875rem', fontSize:isMobile?'0.875rem':'1rem' }}>
                <div style={{padding:isMobile?'0rem 1rem':''}}>Welcome to my newsletter! I will keep you posted with the latest.</div>
            </div>
            <FlexDiv
                style={{justifyContent:'center',width:'100%', paddingBottom:'2rem'}}
            >
            <button style={{...buttonStyles,marginTop:isMobile?'1.5rem':'2.75rem'}} onClick={onClose}>
                <img src={CancelIcon} style={{ width: '1.5rem', height: '1.5rem' }} alt="" />
                Close</button>
                </FlexDiv>
                </div>
            </div>
        </Modal>
    );
};

export default SuccessPopup;
