import React from 'react'
import Navabr from '../components/Navbar/Navabr'
import Hero from '../components/Hero/Hero'
import Companies from '../components/Companies/Companies'
import AboutUs from '../components/AboutUs/AboutUs'
import ProjectsList from '../components/ProjectsList/ProjectsList'
import Footer from '../components/Footer/Footer'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useEffect } from 'react';

const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: 650 });
  const location = useLocation();

  useEffect(() => {
    // Scroll to the target section on mount
    const targetSection = new URLSearchParams(location.search).get('section');
    if (targetSection) {
      scroller.scrollTo(targetSection, {
        duration: 500,
        delay: 100,
        smooth: true,
      });
    }
  }, [location.search]);


  return (
    <div>
      <Navabr />
      <Hero />
      <Companies />
      <AboutUs />
      <ProjectsList />
      <div style={{height:isMobile?'2.5rem':'5rem'}}/>
      <Footer />
    </div>
  )
}

export default Home