import styled from "styled-components";
import CompanyList from '../../assets/CompanyList.svg';
import Ent from '../../assets/ENT.png';
import Ibt from '../../assets/IBT.png';
import Nyt from '../../assets/NYT.png';
import Rh from '../../assets/RH.png';

export const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(128deg, rgba(234, 208, 247, 0.16) 0%, rgba(214, 230, 244, 0.16) 99.98%);    
    @media (max-width: 600px) {
        padding:0.5rem 0rem;
    }   
`;

export const CompanyListImg = styled.image`
    background: url(${CompanyList});
    width: 57.39938rem;
    height: 3.41875rem;
`;

export const EntImg = styled.img`
    background: url(${Ent});
    width: 10rem;
    height: 10rem;
    object-fit: cover;
`;
export const IbtImg = styled.image`
    background: url(${Ibt});
`;
export const NytImg = styled.image`
    background: url(${Nyt});
`;
export const RhImg = styled.image`
    background: url(${Rh});
`;
