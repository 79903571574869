import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../assets/BlackCancel.svg';
import { FlexDiv } from '../../assets/Style';
import { NavabrBtn, SubscribeText } from '../Navbar/Style';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { API_URL } from '../Footer/Data';
import { CircularProgress } from '@mui/material';

const headingStyles = {
    color: '#06152B',
    fontFamily: 'Poppins',
    fontSize: '1.15rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
};

const listStyles = {
    marginTop: '8px',
    color: 'dimgray',
    paddingInlineStart: '30px',
};

const inputStyles = {
    padding: '1px 16px',
    fontSize: '14px',
    lineHeight: '20px',
};

const inputWrapperStyles = {
    marginBottom: '0',
    display: 'flex',
    width: '100%',
    position: 'relative',
    zIndex: 10,
    marginTop: '-3rem'
};

const inputFieldStyles = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    border: '1px solid #e6e6e6',
    borderRightWidth: '0',
    borderRadius: '8px 0 0 8px',
    marginRight: '0',
    width: '100%'
};

const successDiv = {
    textAlign: 'center',
    backgroundColor: '#ddd',
    padding: '20px',
    transition: 'opacity 0.5s ease-in-out', // Add transition property
    opacity: 0, // Initially hide the success message
};

const NewsLetterPopup = ({ isOpen, setIsOpen }) => {
    const isMobile = useMediaQuery({ maxWidth: 650 });
    const isSmallMobile = useMediaQuery({ maxWidth: 560 })
    const [showSuccess, setShowSuccess] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // State to track loading state

    useEffect(() => {
        if (isOpen) {
            setOpacity(1);
            setShowSuccess(false);
        } else {
            setOpacity(0);
        }
    }, [isOpen]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        axios.post(`${API_URL}sendmailtomiller`, { email })
            .then(response => {
                setShowSuccess(true);
                setEmail('')
            })
            .catch(error => {
                console.error('Error sending email:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCloseModal = () => {
        console.log('closing modal')
        setEmail('');
        setOpacity(0);
        setTimeout(() => {
            setShowSuccess(false);
            setIsOpen(false);
        }, 300);
    };

    const modalStyle = {
        overlay: {
            backgroundColor: `rgba(0, 0, 0, ${opacity * 0.5})`,
            zIndex: 100,
            transition: 'background-color 0.3s ease-in-out'
        },
        content: {
            backgroundColor: '#fafafa',
            border: '1px solid #f0f0f0',
            borderRadius: '16px',
            padding: isMobile ? '12px' : '16px',
            textDecoration: 'none',
            boxShadow: '0 0 56px rgba(0, 0, 0, 0.1)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '85%' : '550px',
            height: isSmallMobile ? '250px' : '210px',
            opacity,
            transition: 'opacity 0.3s ease-in-out',
        }
    };

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setOpacity(1);
            }, 100);
        }
    }, [showSuccess]);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Example Modal"
                style={modalStyle}
            >
                <FlexDiv style={{ justifyContent: 'flex-end' }}>
                    <img src={CloseIcon} alt="Close" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
                </FlexDiv>
                <FlexDiv style={{ justifyContent: 'space-between' }}>
                    <div style={headingStyles}>Enter your email to get updates for my Newsletter:</div>
                </FlexDiv>
                <ul style={listStyles}>
                    <li style={{marginBottom:'0.25rem'}}>Helpful frameworks</li>
                    <li style={{ marginBottom: '0.25rem' }}>Useful insights</li>
                    <li>My life</li>
                </ul>
                <div style={{ ...successDiv, opacity: showSuccess ? 1 : 0, padding: isSmallMobile ? '16px' : '20px' }}>
                    Welcome to my newsletter! I'll keep you posted with the latest.
                </div>
                {!showSuccess && (
                    <div style={inputWrapperStyles}>
                        <input
                            type="email"
                            placeholder="Email"
                            onKeyDown={handleKeyDown}
                            style={{ ...inputStyles, ...inputFieldStyles, outline: 'none' }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete='email'
                            id='email'
                            name='email'
                        />
                        <NavabrBtn
                            onClick={handleSubmit}
                            whileHover={{ opacity: 0.8 }}
                            transition={{ duration: 0.3, ease: 'easeInOut' }}
                            style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', cursor: 'pointer', height: '2.75rem' }}
                        >
                            {loading ? (
                                <CircularProgress size={24} style={{ 'color': '#0090FF' }} />
                            ) : (
                                <SubscribeText style={{ fontSize: isMobile ? '0.875rem' : '16px' }}>
                                    Submit
                                </SubscribeText>
                            )}
                        </NavabrBtn>
                    </div>
                )}
            </Modal>
        </div >
    );
}

export default NewsLetterPopup;
