import React, { useState } from 'react'
import { MainDiv, Heading, SimpleText, EmailDiv, Circle, TextInput, SubmitBtn } from './Style'
import { FlexDiv } from '../../assets/Style';
import { SocialLinks, API_URL, EMAIL_REGEX } from './Data';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import SuccessPopup from './SuccessPopup';

const Footer = () => {
  const isSmallLaptop = useMediaQuery({ maxWidth: 1200 });
  const isTab = useMediaQuery({ maxWidth: 920 });
  const isSmallTab = useMediaQuery({ maxWidth: 750 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isSmallMobile = useMediaQuery({ maxWidth: 430 });
  const isSMobile = useMediaQuery({ maxWidth: 410 });
  const [textFieldValue, setTextFieldValue] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setSnackbarMsg('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      clearText();
    }
  };

  const sendEmail = async (email) => {
    try {
      setSendingEmail(true);
      const response = await axios.post(`${API_URL}sendmailtomiller`, { email });
      return response;
    } catch (error) {
      console.log(error);
      setSnackbarMsg("Something went wrong");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      throw error;
    }
  };

  const clearText = async () => {
    if (textFieldValue === '') {
      return;
    } else if (!EMAIL_REGEX.test(textFieldValue)) {
      setSnackbarMsg("Email is incorrect");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } else {
      const response = await sendEmail(textFieldValue);
      setSendingEmail(false);
      if (response.data.data.status === '200') {
        setOpenSuccessPopup(true);
        setTextFieldValue('');
      }
    }
  };

  const openSocialTab = (urlLink) => {
    window.open(urlLink, '_blank');
  };

  const handleSuccessPopupClose = () => {
    setOpenSuccessPopup(false);
  };

  return (
    <>
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackbarSeverity}
        >
          {snackbarMsg}
        </MuiAlert>
      </Snackbar>
      <SuccessPopup isOpen={openSuccessPopup} onClose={handleSuccessPopupClose} /> */}
      <MainDiv id='Subscribe' style={{ padding: isSmallMobile?'2rem 0rem 2rem 0rem':'2.5rem 0rem 2.5rem 0rem' }}>
        {/* <Heading
          style={{
            fontSize: isSmallLaptop ? '1.75rem' : '',
            marginRight: isSmallLaptop ?
              (isTab ?
                (isSmallTab ?
                  (isMobile ? '0rem' : '10rem')
                  : '20rem')
                : '30rem')
              : '',
            width: isSmallMobile ? '85%' : '',
            textAlign: isMobile ?
              (isSmallMobile ? '' : 'center') : '',
            marginLeft: isSmallMobile ? (isSMobile ? '1rem' : '2rem') : ''
          }}
        >
          Want to follow my learnings?
        </Heading>
        <FlexDiv style={{ justifyContent: 'center' }}>
          <EmailDiv style={{ justifyContent: 'center' }}>
            <TextInput type="text" placeholder="example@gmail.com" style={{ fontSize: isSmallLaptop ? '1rem' : '', width: isSmallLaptop ? (isTab ? (isSmallTab ? (isMobile ? (isSmallMobile ? (isSMobile ? '16rem' : '18rem') : '20rem') : '30rem') : '40rem') : '50rem') : '62rem' }}
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {sendingEmail ?
              <FlexDiv style={{ width: isMobile ? '4rem' : '6rem', justifyContent: 'flex-end' }}>
                <CircularProgress color="primary" style={{ width: '20px', height: '20px' }} />
              </FlexDiv>
              :
              <button style={{ display: 'contents', cursor: 'pointer' }}
                onClick={clearText}
              >
                <SubmitBtn style={{ fontSize: isSmallLaptop ? '1rem' : '' }}>
                  Submit
                </SubmitBtn>
              </button>
            }
          </EmailDiv>
        </FlexDiv> */}
        <FlexDiv style={{
          justifyContent: 'center', gap: isSmallLaptop ?
            (isTab ?
              (isSmallTab ?
                (isMobile ? '7rem' : '10rem')
                : '20rem')
              : '30rem')
            : '40.51rem'
        }}>
          <SimpleText
            style={{
              fontSize: isSmallLaptop ? '0.875rem' : '',
              lineHeight: isSmallLaptop ? '1.375rem' : '',
              width: isMobile ? '8.625rem' : ''
            }}
          >
            ©2024. Frank Miller. All Rights Reserved.
          </SimpleText>
          <FlexDiv style={{ gap: '0.87rem' }}>
            {SocialLinks.map((data, index) => (
              <button
                style={{ display: 'contents', cursor: 'pointer' }}
                onClick={() => { openSocialTab(data.url) }}
              >
                <Circle
                  style={{
                    width: isTab ? '1.5rem' : '',
                    height: isTab ? '1.5rem' : ''
                  }}
                >
                  {data.name}
                </Circle>
              </button>
            ))}
          </FlexDiv>
        </FlexDiv>
      </MainDiv>
    </>
  )
}

export default Footer
