import styled from "styled-components";
import Facebook from '../../assets/Facebook.svg';
import FacebookTwo from '../../assets/BlackFb.svg';
import LinkedIn from '../../assets/LinkedIn.svg';
import Insta from '../../assets/Insta.svg';
import LinkedInTwo from '../../assets/BlackLI.svg';
import InstaTwo from '../../assets/BlackInsta.svg';

export const Heading = styled.div`
    color: #FFF;
    font-family: Poppins;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-right: 29rem;
`;

export const MainDiv = styled.div`
    background: #06152B;
    display: flex;
    flex-direction: column;
`;

export const SimpleText = styled.div`
    color: #FFF;
    font-family: Poppins;
    font-size: 0.96875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
`;

export const FacebookImg = styled.image`
    background: url(${Facebook});
    width: 0.5715rem;
    height: 0.875rem;
    transition: background 0.3s;    
`;

export const LinkedInImg = styled.image`
    background: url(${LinkedIn});
    width: 0.78694rem;
    height: 0.875rem;
    transition: background 0.3s;    
`;

export const InstaImg = styled.image`
    background: url(${Insta});
    width: 0.78694rem;
    height: 0.875rem;
    transition: background 0.3s;    
`;

export const Circle = styled.div`
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.25rem;
    border: 1px solid #5E6D7E;
    transition: background 0.3s;    
    &:hover {
        background: #fff; 
        ${FacebookImg} {
            background: url(${FacebookTwo});
        }               
        ${InstaImg} {
            background: url(${InstaTwo});
        }               
        ${LinkedInImg} {
            background: url(${LinkedInTwo});
        }               
    }
`;

export const EmailDiv = styled.div`
    display: flex;
    padding: 0.76875rem 0rem 0.5625rem 0rem;
    justify-content: center;
    align-items: center;
    border-radius: 0rem 0rem 0.0625rem 0rem;
    border-bottom: 1px solid #4A4A4A;    
`;

export const TextInput = styled.input`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  font-family: Roboto;
  font-size: 1.4375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #7A7A7A;
  margin-left:0.75rem;
`;

export const SubmitBtn = styled.div`
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 100% */
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    margin-top: 0.3rem;
`;