import React from 'react'
import { MeetFrankImg, Heading, Detail, ProjectsBtn, SmallMeetFrankImg } from './Style'
import { FlexDiv, AnchorTag } from '../../assets/Style'
import Eye from '../../assets/ViewProjectEye.svg';
import ViewProject from '../../assets/ViewProjects.svg'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { ContentBtn } from '../Navbar/Style';

const AboutUs = ({headColor='#06152B', textColor='rgba(6, 21, 43, 0.80)', isHome=true}) => {
    const isLaptop = useMediaQuery({ maxWidth: 1300 });
    const isSmallLaptop = useMediaQuery({ maxWidth: 1150 });
    const isMobile = useMediaQuery({ maxWidth: 650 });
    const navigate = useNavigate();

    return (
    <FlexDiv id='About' style={{paddingTop:isMobile?'2rem':'5.02rem', justifyContent:'center', paddingBottom: '5rem', gap:isLaptop?'1.5rem':'4.81rem', flexDirection:isSmallLaptop?'column':'', alignItems:isSmallLaptop?'center':''}}>
        <FlexDiv>
            {isMobile?
                    <SmallMeetFrankImg />
                :
                    <MeetFrankImg />
            }
        </FlexDiv>
        <FlexDiv style={{flexDirection:'column', paddingTop:'0.75rem', paddingRight:isSmallLaptop?isMobile?'':'4.5rem':''}}>
            <Heading
                style={{fontSize:isMobile?'1.75rem':'', color:headColor}}
            >
                Meet Frank
            </Heading>
            <Detail style={{marginTop:'1rem', fontSize:isMobile?'1rem':'', width:isMobile?'22.375rem':'',marginBottom:'1rem', color:textColor}}>
               The computer became a safe haven for me very early on in life, and at just 11 years old I started my first business in the consumer technology space.
            </Detail>
            <Detail style={{fontSize:isMobile?'1rem':'', width:isMobile?'22.375rem':'',marginBottom:'1rem', color:textColor}}>
                "Any sufficiently advanced technology is indistinguishable from magic."           
            </Detail>
            <Detail style={{fontSize:isMobile?'1rem':'', width:isMobile?'22.375rem':'',marginBottom:'1rem', color:textColor}}>
               In the past four years, I have worked with hundreds of online business owners and real estate investors to convert more leads into deals by building sales solutions and software that optimize client acquisition.
            </Detail>
            <Detail style={{fontSize:isMobile?'1rem':'', width:isMobile?'22.375rem':'',marginBottom:'2rem', color:textColor}}>
               Now, I am expanding our services and building a portfolio of public and private applications that service several industries such as Real Estate, Solar, Healthcare, and more.
            </Detail>
            {isHome?
                <AnchorTag
                    to='Projects'
                    smooth={true}  
                >
                <ProjectsBtn
                        whileHover={{ opacity: 0.8, scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}    
                        style={{
                            width:isMobile?'10.0625rem':'',
                            height:isMobile?'3.125rem':'',
                            padding:isMobile?'0rem':''
                        }}                
                >
                    <img alt='' src={Eye} 
                        style={{
                            width:isMobile?'1rem':'',
                            height:isMobile?'1rem':''
                        }}                                                            
                    />
                    <img alt='' src={ViewProject} 
                        style={{
                            width:isMobile?'5.9375rem':'',
                            height:isMobile?'1.375rem':''
                        }}                                                            
                    />
                </ProjectsBtn>
            </AnchorTag>
            :
            <ContentBtn onClick={()=>{navigate(`/?section=Projects`)}}>
                <ProjectsBtn
                        whileHover={{ opacity: 0.8, scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}    
                        style={{
                            width:isMobile?'10.0625rem':'',
                            height:isMobile?'3.125rem':'',
                            padding:isMobile?'0rem':''
                        }}                
                >
                    <img alt='' src={Eye} 
                        style={{
                            width:isMobile?'1rem':'',
                            height:isMobile?'1rem':''
                        }}                                                            
                    />
                    <img alt='' src={ViewProject} 
                        style={{
                            width:isMobile?'5.9375rem':'',
                            height:isMobile?'1.375rem':''
                        }}                                                            
                    />
                </ProjectsBtn>
            </ContentBtn>
            }
        </FlexDiv>
    </FlexDiv>
  )
}

export default AboutUs
