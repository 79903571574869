import React, { useState } from 'react'
import { FlexDiv, AnchorTag } from '../../assets/Style'
import { Heading, Detail, FrankImg, SubscribeBtn, SubscribeText, ProjectsBtn, BlurredBackground } from './Style'
import HeroLine from '../../assets/HeroLine.png';
import SubscribeBtnImg from '../../assets/subscribe_btn_img.svg';
import Eye from '../../assets/ViewProjectEye.svg';
import ViewProject from '../../assets/ViewProjects.svg'
import { useMediaQuery } from 'react-responsive';
import { SocialLinks } from '../Footer/Data';
import { Circle } from '../Footer/Style';
import NewsLetterPopup from './NewsLetterPopup';
import FrankPicture from '../../assets/FranksImg.png';

const Hero = () => {
    const isLaptop = useMediaQuery({ maxWidth: 1300 });
    const isSmallLaptop = useMediaQuery({ maxWidth: 1150 });
    const isMobile = useMediaQuery({ maxWidth: 550 });
    const isTab = useMediaQuery({ maxWidth: 920 });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openSocialTab = (urlLink) => {
        window.open(urlLink, '_blank');
    };

    return (
        <>
            <NewsLetterPopup isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            <FlexDiv id='Home' style={{ paddingTop: isSmallLaptop ? '8rem' : '10.25rem', justifyContent: 'center', paddingBottom: isSmallLaptop ? '2.5rem' : '0rem', backgroundColor: '#000', gap: isLaptop ? '2rem' : '8.19rem' }}>
                <FlexDiv style={{ flexDirection: 'column', paddingTop: isSmallLaptop ? '' : '4.5rem', alignItems: isSmallLaptop ? 'center' : '', marginLeft:'2rem'}}>
                    <Heading
                        style={{
                            textAlign: isSmallLaptop ? 'center' : '',
                            fontSize: isMobile ? '2.5rem' : '',
                            lineHeight: isMobile ? '3.25rem' : '',
                            maxWidth: isMobile ? '17rem' : ''
                        }}
                    >
                        👋 Hey, I am Frank Miller
                    </Heading>
                    <img alt='' src={HeroLine} style={{ width: isMobile ? '17rem' : '23.75rem', height: isMobile ? '1.91963rem' : '2.6875rem', marginTop: isMobile ? '-1.5rem' : '-1rem', marginBottom: '1rem' }} />
                    <Detail
                        style={{
                            textAlign: isSmallLaptop ? 'center' : '',
                            fontSize: isMobile ? '0.875rem' : '',
                            lineHeight: isMobile ? '1.375rem' : '',
                            width: isMobile ? '22.375rem' : ''
                        }}
                    >
                        Empowering 100+ businesses in various industries such as Real Estate, Solar, Insurance, Healthcare, Merchant Services, and more with innovative technologies and strategies which help them achieve goals and build success in todays competitive marketplace.
                    </Detail>
                    <FlexDiv style={{ marginTop: '2rem', gap: isMobile ? '0.5rem' : '1.5rem', justifyContent: isSmallLaptop ? 'center' : '' }}>
                        <AnchorTag
                            to='Projects'
                            smooth={true}
                        >
                            <ProjectsBtn
                                whileHover={{ opacity: 0.8, scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ duration: 0.3, ease: 'easeInOut' }}
                                style={{
                                    width: isMobile ? '10.0625rem' : '',
                                    height: isMobile ? '3.125rem' : '',
                                    padding: isMobile ? '0rem' : ''
                                }}
                            >
                                <img alt='' src={Eye}
                                    style={{
                                        width: isMobile ? '1rem' : '',
                                        height: isMobile ? '1rem' : ''
                                    }}
                                />
                                <img alt='' src={ViewProject}
                                    style={{
                                        width: isMobile ? '5.9375rem' : '',
                                        height: isMobile ? '1.375rem' : ''
                                    }}
                                />
                            </ProjectsBtn>
                        </AnchorTag>

                        <SubscribeBtn
                            onClick={() => setIsModalOpen(true)}
                            whileHover={{ opacity: 0.8, scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ duration: 0.3, ease: 'easeInOut' }}
                            style={{
                                width: isMobile ? '8.625rem' : '',
                                height: isMobile ? '3.125rem' : '',
                                cursor: 'pointer'
                            }}
                        >
                            <img alt='' src={SubscribeBtnImg}
                                style={{
                                    width: isMobile ? '1rem' : '',
                                    height: isMobile ? '1rem' : ''
                                }}
                            />
                            <SubscribeText
                                style={{
                                    fontSize: isMobile ? '0.875rem' : '',
                                }}
                            >
                                Join My Newsletter
                            </SubscribeText>
                        </SubscribeBtn>
                    </FlexDiv>
                    <FlexDiv style={{ gap: '0.87rem', marginTop: '2.75rem' }}>
                        {SocialLinks.map((data, index) => (
                            <button
                                style={{ display: 'contents', cursor: 'pointer' }}
                                onClick={() => { openSocialTab(data.url) }}
                            >
                                <Circle
                                    style={{
                                        width: isTab ? '1.5rem' : '',
                                        height: isTab ? '1.5rem' : '',
                                        borderRadius: '2.3125rem',
                                        border: '1px solid #fff'
                                    }}
                                >
                                    {data.name}
                                </Circle>
                            </button>
                        ))}
                    </FlexDiv>
                </FlexDiv>
                {!isSmallLaptop
                    &&
                    <>
                        <FlexDiv style={{ flexDirection: 'column', position: 'relative' }}>
                            <FrankImg alt='' src={FrankPicture}/>
                            <BlurredBackground />
                        </FlexDiv>
                    </>
                }
            </FlexDiv>
        </>
    )
}

export default Hero