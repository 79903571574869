import styled from "styled-components";
import BlueLines from '../../assets/BlueLines.svg';
import LulyImage from '../../assets/LulyImg.svg';
import ParetoImg from '../../assets/ParetoImg.svg';
import SimplifyImg from '../../assets/SimplifyImg.svg';
import ArrowBlue from '../../assets/BlueArrow.svg';
import SmallLulyImage from '../../assets/SmallLulyAiImg.svg';
import SmallParetoImg from '../../assets/SmallParetoImg.svg';
import SmallSimplifyImg from '../../assets/SmallSimplifyImg.svg';

export const MainDiv = styled.div`
    padding: 5rem 0rem;
    background: linear-gradient(128deg, rgba(234, 208, 247, 0.16) 0%, rgba(214, 230, 244, 0.16) 99.98%);   
`;

export const Heading = styled.div`
    color: #06152B;
    font-family: Poppins;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const Details = styled.div`
    color: rgba(6, 21, 43, 0.80);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    letter-spacing: 0.03125rem;
    opacity: 0.6;
`;

export const BlueLine = styled.image`
    background: url(${BlueLines});
    width: 2rem;
    height: 2.19rem;    
`;

export const Card = styled.div`
    border-radius: 1rem;
    border: 1px solid rgba(128, 159, 184, 0.20);
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    display:flex;
    flex-direction: column;
    width: max-content;
`;

export const InnerCard = styled.div`
    display: inline-flex;
    align-items: center;
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);
    width: max-content;
`;

export const LulyImg = styled.image`
    background: url(${LulyImage});
    width: 5rem;
    height: 2.70494rem;
`;

export const ParetoImage = styled.image`
    background: url(${ParetoImg});
    width: 13.655rem;
    height: 1.97938rem;
`;

export const SimplifyImage = styled.image`
    background: url(${SimplifyImg});
    width: 12.745rem;
    height: 3.04875rem;
`;

export const SmallLulyImg = styled.image`
    background: url(${SmallLulyImage});
    width: 3.75rem;
    height: 2.02869rem;
`;

export const SmallParetoImage = styled.image`
    background: url(${SmallParetoImg});
    width: 13.655rem;
    height: 1.97938rem;
`;

export const SmallSimplifyImage = styled.image`
    background: url(${SmallSimplifyImg});
    width: 8.36081rem;
    height: 2rem;
`;

export const CardHeading = styled.div`
    color: #06152B;
    font-family: Poppins;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5rem;
    text-transform: capitalize;
`;

export const CardDetails = styled.div`
    color: rgba(6, 21, 43, 0.80);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125rem;
    opacity: 0.6;
    height: 8.625rem;
`;

export const LearnMore = styled.div`
    color: var(--Primary-blue, #51B2FE);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
`;

export const BlueArrowImg = styled.image`
    background: url(${ArrowBlue});
    width: 1rem;
    height: 0.875rem;
`;

export const SoldDiv = styled.div`
    display: flex;
    padding: 0.3125rem 0.625rem;
    align-items: center;
    border-radius: 0.3125rem;
    background: var(--Gradient, linear-gradient(90deg, #0090FF 0%, #36DAE9 100%));
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 150% */
    text-transform: capitalize;    
    margin-left: -1rem;
    @media (max-width: 1300px) {
        font-size: 0.75rem;
        margin-left: -0.75rem;
        padding: 0.25rem 0.5rem;
    }    
`;