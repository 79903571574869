import styled from 'styled-components';
import MeetFrank from '../../assets/MeetFrank.svg';
import { motion } from 'framer-motion';
import SmallMeetFrank from '../../assets/Parachute.svg';

export const MeetFrankImg = styled.image`
    background: url(${MeetFrank});
    width: 37.5rem;
    height: 37.5rem;    
    border-radius: 0.9375rem;
`;

export const SmallMeetFrankImg = styled.image`
    background: url(${SmallMeetFrank});
    width: 22.375rem;
    height: 22.375rem;    
    border-radius: 0.9375rem;
`;

export const Heading = styled.div`
    color: #06152B;
    font-family: Poppins;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const Detail = styled.div`
    width: 32.6875rem;
    color: rgba(6, 21, 43, 0.80);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    letter-spacing: 0.03125rem;
    opacity: 0.6;    
`;

export const ProjectsBtn = styled(motion.div)`
    display: inline-flex;
    padding: 1.0625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1rem;
    border: 1px solid #0090FF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);  
    width: max-content;  
`;

export const AboutUsPageHeader= styled.div`
    color: #06152B;
    font-family: Poppins;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 600px) {
        font-size: 1.75rem;
    }
`;

export const AboutUsPageText = styled.div`
    color: rgba(6, 21, 43, 0.80);
    font-family: Poppins;
    opacity: 0.6;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 155.556% */
    letter-spacing: 0.03125rem;
    @media (max-width: 600px) {
        font-size: 1rem;
        line-height: 1.5rem; /* 150% */
    }
`;